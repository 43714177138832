<template>
  <b-card
    no-body
    class="mb-0">

    <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Table.Calibration.Title') }} </label>

          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-dropdown
                v-ripple.600="'rgba(40, 199, 111, 0.15)'"
                split
                :text="$t('Action')"
                variant="outline-success"
              >
                <b-dropdown-item v-b-modal.modal-calibration>
                  {{ $t('Calibration') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>

      </div>
    <!-- table -->
    <b-table
      :fields="fields"
      :items="items"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      show-empty
      @filtered="onFiltered"
      striped
      responsive
      class="mb-0"
    >
      <template #head()="scope">
        <div
          class="text-nowrap">
          {{ $t(`Table.Calibration.${scope.label}`) }}
          </div>
      </template>

       <!-- Details -->
      <template #row-details="row">
          <b-card v-if="row.item.id === task_id ? 'hidden' : ''">
            <b-row>
              <b-col
                md="12"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                 <b-table
                    :fields="detailField"
                    :items="productDetails"
                    responsive
                    sticky-header
                    head-variant="light"
                 >
                    <template #head()="scope">
                      <div
                        class="text-nowrap">
                        {{ $t(`Table.Calibration.Detail.${scope.label}`) }}
                      </div>
                    </template>

                    <template #cell(id)="data">
                      <div class="text-nowrap">
                        <feather-icon
                          :id="`action-calibre-${data.item.id}`"
                          icon="ClipboardIcon"
                          class="cursor-pointer"
                          v-b-modal.modal-calibration
                          size="24"
                      />
                      <b-tooltip
                          title="Tindakan Kalibrasi"
                          class="cursor-pointer"
                          :target="`action-calibre-${data.item.id}`"
                      />
                      </div>
                    </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
      </template>
      <!-- End Details -->

      <!-- Column: Actions -->
      <template #cell(id)="data">
        <div class="text-nowrap">
        <b-form-checkbox-group
                    class="mt-1"
                  >
          <feather-icon
                :icon="data.detailsShowing ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                class="cursor-pointer"
                size="24"
                @click="data.toggleDetails(); fetchDataDetailCalibration(data.item.id);"
            />
            <b-dropdown
                v-ripple.600="'rgba(40, 199, 111, 0.15)'"
                split
                :text="$t('Action')"
                variant="outline-success"
              >
                <b-dropdown-item :to="{ name: 'instansi-product-preview', params: {id_task: `${data.item.id}`} } ">
                  .pdf
                </b-dropdown-item>
                <b-dropdown-item @click="exportButton()">
                  .csv
                </b-dropdown-item>
              </b-dropdown>
          </b-form-checkbox-group>
        </div>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Table.Showing') }} {{ $t('Table.Of') }} {{ $t('Table.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            :value="1"
            :total-rows="10"
            :per-page="pageLength"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <b-modal
      id="modal-calibration"
      title="Pengujian dan Kalibrasi"
      hide-footer
      ref="my-modal-calibration"
    >
    <validation-observer
          ref="actionForm"
        >
        <b-form
          @submit.prevent="created"
        >
      <div class="d-block">
        <div role="group">
                    <label for="input-live">{{ $t('Form.Action.Status') }}</label>
                        <validation-provider
                            #default="{ errors }"
                            name="Name"
                            vid="name"
                            rules="required"
                        >
                            <b-form-group>
                              <v-select
                                v-model="status"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :options="statusOptions"
                              />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
        <div role="group">
                    <label for="input-live">{{ $t('Form.Action.Date') }} </label>
                        <validation-provider
                            #default="{ errors }"
                            name="Name"
                            vid="name"
                            rules="required"
                        >
                          <flat-pickr
                            v-model="date"
                            class="form-control"
                            :config="{ dateFormat: 'd-m-Y'}"
                            placeholder="DD-MM-YYYY"
                          />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
        <div role="group">
                      <label for="input-live">{{ $t('Form.Action.Description') }}</label>
                      <b-form-textarea
                          id="notes"
                          v-model="description"
                          rows="2"
                          />
                  </div>
      </div>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-3"
        variant="primary"
        type="submit"
        block
      >
        {{ $t('Submit') }}
      </b-button>
</b-form>
  </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BAvatar, BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BModal, VBModal, BLink, BFormCheckbox,
  BTable, BFormGroup, BFormInput, BInputGroupAppend, BTooltip, BFormCheckboxGroup, BFormTextarea
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import * as XLSX from 'xlsx'
import moduleApi from '@/store/module'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BPagination,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BModal,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BTooltip,
    BFormCheckboxGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          label: 'Actions',
          key: 'id',
        },
        {
          label: 'Task',
          key: 'description',
        },
        {
          label: 'users',
          key: 'name',
        },
        {
          label: 'Status',
          key: 'status',
        }
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      detailField: [
         {
           label: 'Actions',
           key: 'id',
         },
         {
           label: 'Instansi',
           key: 'instansi_name',
         },
         {
           label: 'Product',
           key: 'product_name',
         },
         {
           label: 'Status',
           key: 'status',
         },
      ],
      productDetails: [],
      task_id: '',

      status: '',
      date: '',
      description: '',
      required,
      statusOptions:  [{ title: 'Laik Pakai', id:1 }, { title: 'Laik Pakai Kuning', id:2 }, { title: 'Tidak Laik Pakai', id:3 }]

    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {
    fetchDataListTask() {
      moduleApi.fetchDataListTask()
        .then(response => {
            this.items = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },
    fetchDataDetailCalibration(task_id) {
      moduleApi.fetchDataDetailCalibration(task_id)
          .then(response => {
              this.productDetails = response.data.data
              this.task_id = task_id
          })
          .catch(error => {
            this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
    created() {
          this.$refs.actionForm.validate().then(success => {
            if (success) {
              moduleApi.createAction({
                date: this.date,
                description: this.description,
                status: this.status.id
              })
                .then(response => {
                  console.log('this.instansi')
                })
                .catch(error => {
                  this.$refs.warehouseForm.setErrors(error.response.data.error)
                })
            }
       })
    }
  },
  created() {
    this.fetchDataListTask()
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

